import React, { useState, useEffect, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/Check';
import Can from '../../../roles/Can';
import { currencyFormatter } from '../PurchaseOrderDetailsHelpers';
import { PurchaseOrderItem } from '../../../common/Types/ApiOsposDbModels/PurchaseOrderItemTypes';
import { TransactedReceiptSerialSnapshot } from '../../../common/Types/DbSnapshots/InventorySerialEventDbSnapshotTypes';
import { GlueCopyToClipboardIconButton } from '../../Presentational/GlueCopyToClipboardIconButton';

type POLineItemProps = {
  poItem: PurchaseOrderItem;
  poEditingDisabled: boolean;
  finished: boolean;
  role: string;
  updatePOItem: (purchaseOrderItem: PurchaseOrderItem) => Promise<void>;
  deletePOItem: (purchaseOrderItem: PurchaseOrderItem) => Promise<void>;
  initializeReceiving: (poItem: PurchaseOrderItem) => void;
  initializeUnReceiving: (poItem: PurchaseOrderItem) => void;
  isOrderSent: boolean;
  reviewSerials: (
    poItemId: number
  ) => Promise<TransactedReceiptSerialSnapshot[][] | 'No serials found'>;
};

export const POLineStyles = makeStyles({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    border: '1px solid #ccc',
    margin: '1rem 0',
    padding: '1rem'
  },
  row: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  textField: {
    // cursor: "not-allowed"
  },
  disabled: {
    color: '#000',
    cursor: 'not-allowed'
  },
  bad: {
    color: '#e50a03',
    '&:hover': {
      color: '#f74a45'
    }
  }
});

export default function POLineItem(props: POLineItemProps) {
  const classes = POLineStyles();
  const {
    poItem,
    poEditingDisabled,
    finished,
    role,
    updatePOItem,
    deletePOItem,
    initializeReceiving,
    initializeUnReceiving,
    reviewSerials,
    isOrderSent
  } = props;

  const [localPOItemData, setLocalPOItemData] = useState<PurchaseOrderItem>(poItem);
  const receiveButtonRef = useRef<HTMLButtonElement>(null);
  const unReceiveButtonRef = useRef<HTMLButtonElement>(null);
  const viewSerialsButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (
      poItem.qty_received !== localPOItemData.qty_received ||
      poItem.line_total !== localPOItemData.line_total ||
      poItem.expected_arrival_date !== localPOItemData.expected_arrival_date ||
      poItem.carrier_name !== localPOItemData.carrier_name ||
      poItem.tracking_number !== localPOItemData.tracking_number
    ) {
      setLocalPOItemData(poItem);
    }
    // Adding a disable-ES-Lint statement for exhaustive-deps on localPOItemData properties. This disabling
    // reflects the ignoring that was already in place when this component was first written. It appears to
    // work because the component only cares if the values of the parent poItem properties have changed.
    // It's wonky and might be prone to bugs if this feature becomes extended / modified.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poItem]);

  const handleChange = (event: any) => {
    const newData = { ...localPOItemData, [event.target.name]: event.target.value };
    setLocalPOItemData(newData);
  };

  const handleUpdate = () => updatePOItem(localPOItemData);

  const allItemsReceived = poItem.qty_received >= poItem.qty_ordered;

  return (
    <Grid className={classes.root} container justify="space-between" alignItems="center">
      <Grid container item direction="column" xs={12} md={4} lg={3} xl={2}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 15 }}>
            <Typography style={{ fontSize: 10, lineHeight: 1 }}>Model Number</Typography>
            <Typography style={{ fontSize: 14, lineHeight: 1, whiteSpace: 'nowrap' }}>
              <GlueCopyToClipboardIconButton parentTypographyVariant="h6">
                {poItem.product ? poItem.product.model_number : ''}
              </GlueCopyToClipboardIconButton>
            </Typography>
          </div>
          <div>
            <Typography style={{ fontSize: 10, lineHeight: 1, marginBottom: 5 }}>MPN</Typography>
            <Typography style={{ fontSize: 14, lineHeight: 1 }}>
              {poItem.product ? poItem.product.part_number : ''}
            </Typography>
          </div>
        </div>
        <div className={classes.row}>
          {!finished && (
            <>
              {allItemsReceived ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  startIcon={<CheckIcon />}
                  title="There are no more items to receive on this PO line."
                >
                  Received
                </Button>
              ) : (
                <Can
                  role={role || 'user'}
                  perform="purchase-order:receive"
                  yes={() => (
                    <>
                      <Button
                        buttonRef={receiveButtonRef}
                        variant="contained"
                        onClick={() => {
                          initializeReceiving(poItem);
                          if (receiveButtonRef.current) {
                            receiveButtonRef.current.blur();
                          }
                        }}
                      >
                        Receive
                      </Button>
                    </>
                  )}
                />
              )}
              {poItem.qty_received > 0 && (
                <Can
                  role={role || 'user'}
                  perform="purchase-order:un-receive"
                  yes={() => (
                    <Button
                      buttonRef={unReceiveButtonRef}
                      variant="outlined"
                      onClick={() => {
                        initializeUnReceiving(poItem);
                        if (unReceiveButtonRef.current) {
                          unReceiveButtonRef.current.blur();
                        }
                      }}
                    >
                      Un-Receive
                    </Button>
                  )}
                />
              )}
            </>
          )}
          <Button
            onClick={() => {
              reviewSerials(poItem.purchase_order_id);
              if (viewSerialsButtonRef.current) {
                viewSerialsButtonRef.current.blur();
              }
            }}
            buttonRef={viewSerialsButtonRef}
          >
            View Serials
          </Button>
          {!poItem.qty_received && (
            <Button
              disabled={poEditingDisabled}
              onClick={() => deletePOItem(poItem)}
              className={classes.bad}
            >
              Delete
            </Button>
          )}
        </div>
      </Grid>
      <Grid container item xs={12} md={8} lg={9} xl={10} spacing={4}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            inputProps={{
              className: poEditingDisabled ? classes.disabled : classes.textField
            }}
            disabled={poEditingDisabled}
            fullWidth
            name="qty_ordered"
            label="Quantity Ordered"
            value={localPOItemData.qty_ordered}
            onChange={e => handleChange(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            inputProps={{
              className: poEditingDisabled ? classes.disabled : classes.textField
            }}
            fullWidth
            name="invoice_cost"
            disabled={poEditingDisabled}
            label="Item Cost"
            value={localPOItemData.invoice_cost}
            onChange={e => handleChange(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextField
            type="date"
            inputProps={{
              className: poEditingDisabled ? classes.disabled : classes.textField
            }}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
            name="expected_arrival_date"
            label="Expected Arrival Date"
            disabled={poEditingDisabled}
            value={localPOItemData.expected_arrival_date}
            onChange={e => handleChange(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextField
            inputProps={{ className: classes.disabled }}
            disabled={poEditingDisabled}
            fullWidth
            name="carrier_name"
            label="Carrier"
            value={localPOItemData.carrier_name}
            onChange={e => handleChange(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextField
            inputProps={{
              className: poEditingDisabled ? classes.disabled : classes.textField
            }}
            disabled={poEditingDisabled}
            fullWidth
            name="tracking_number"
            label="Tracking Number"
            value={localPOItemData.tracking_number}
            onChange={e => handleChange(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            inputProps={{
              className: poEditingDisabled ? classes.disabled : classes.textField
            }}
            disabled
            fullWidth
            name="qty_received"
            label="Quantity Received"
            value={localPOItemData.qty_received}
            onChange={e => handleChange(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <Typography variant="subtitle2">
            <strong>Line Total</strong>
          </Typography>
          <Typography variant="subtitle1">
            {currencyFormatter.format(Number(poItem.line_total))}
          </Typography>
        </Grid>
        {!poEditingDisabled && (
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={1}
            alignItems="center"
            justify="center"
          >
            <Button onClick={handleUpdate} variant="contained" size="small">
              Update
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
